@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700;800;900&family=Roboto+Slab:wght@100;200;300;400;500;600;700;800;900&display=swap");

@import url('https://fonts.googleapis.com/css2?family=Outfit:wght@100;200;300;400;500;600;700;800;900&display=swap');


$primaryFontFamily: "Montserrat", sans-serif;
$secondaryFontFamily: "Roboto Slab", serif;
$alternateFontFamily: 'Outfit', sans-serif;
$bolder: 900;
$bold: 700;
$semibold: 600;
$medium: 500;
$regular: 400;
$light: 300;
$extralight: 200;
$thin: 100;
