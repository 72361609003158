$bodyBGColor: #f5f5f5;
$primaryColor: #3d3d3c;
$secondaryColor: #32c5ff;
$bordercolor: #d8d8d8;
$white: #fff;
$greywhite: #e6e6e6;
$black: #000;
$lightblack: #6e6e6e;
// $danger: #dc3545;
$grey: #666;
$lightGrey: #999;
$blue: #1fa2ff;
$lightblue: #12d8fa;
$activeSurvey: #81d888;
$closeSurvey: #d88181;
$commingSurvey: #17a2b8;
$draftSurvey: rgba(#f9b4378a, 0.54);
$red:#dc3545;
$green: #4caf50;
$S-Requested: #007BFF;
$S-Progress: #FFC107;
$S-Completed: #28A745;
$S-Canceled: #FE2F2F;
